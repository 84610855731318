﻿

(function () {
    "use strict";
    angular.module("common.services")
        .factory("vvPackageResource", ["$resource", "appSettings", vvPackageResource]);
    function vvPackageResource($resource, appSettings) {
        return $resource(appSettings.serverPath + "/api/vvPackage", { id: '@id' },
            {
                'save':
                {
                    method: 'POST',
                    isArray: false,
                    url: appSettings.serverPath + 'api/vvPackage'
                },
                'update':
                {
                    method: 'PUT',
                    isArray: false,
                    url: appSettings.serverPath + 'api/vvPackage/update'
                },
                'get':
                {
                    method: 'GET',
                    isArray: true,
                    url: appSettings.serverPath + 'api/vvPackage'
                },
                
                'getproductDropdown':
                {
                    method: 'GET',
                    isArray: true,
                    url: appSettings.serverPath + 'api/VVproducts/getproductDropdown'
                }


            })
    }
}())